import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class DonationBackendService {
  public httpOptions!: HttpHeaders
  private _donationEndPoint: string

  constructor(
    public _httpClient: HttpClient
  ) {
    this._donationEndPoint = environment.APIEndPointBackend + environment.Donation
  }

  /* GET DONATION BY ID USER DONATE */
  public getDonationByIdUserDonate(idUserDonate: string, idUserToDonate: string): Observable<any> {
    return this._httpClient.get(this._donationEndPoint + idUserDonate.concat("/").concat(idUserToDonate))
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Donation retrieve problem: ", error)
          return error
        })
      )
  }

  /* GET DONATION BETWEEN DATES */
  public getDonationsBetweenDates(dates: any): Observable<any> {
    return this._httpClient.post(this._donationEndPoint + environment.GetDonationsBetweenDates, dates)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error in getDonations between dates: ", error)
          return error
        })
      )
  }
}
<div class="page-title-area">
    <div class="container">
        <a [href]="siteDomain" class="logo"><img src="assets/img/tarot-logo.jpg" alt="Logo"></a>
        <div class="page-title-item">
            <h2>TOT Online - Sesión Online</h2>
        </div>
    </div>
</div>

<ngx-guided-tour></ngx-guided-tour>

<section class="online-session-area pt-50 pb-70">

    <div class="streaming-video-container">

        <canvas #canvasPreview style="display: none;"></canvas>

        <div #videoContainer class="video-container" style="display: none;">
            <video #localVideo autoplay playsinline (loadedmetadata)="onLoadedMetadata($event)"></video>
            <video #remoteVideo autoplay playsinline muted style="display: none"></video>
            <div class="video-buttons" *ngIf="typeUser == '2'" >
                <i *ngIf="!isMuted" class="fa fa-microphone cta-common" aria-hidden="true" (click)="doMuteOrUnmuteAudioStreaming()"></i>
                <i *ngIf="isMuted" class="fa fa-microphone-slash cta-common background-red" aria-hidden="true" (click)="doMuteOrUnmuteAudioStreaming()"></i>
            </div>
        </div>

        <div *ngIf="typeUser != '2' && !inStreaming" class="not-live-streaming">
            El maestro {{seerData.name}} no está emitiendo en directo
        </div>

        <div *ngIf="room" class="room-info">
            <div class="room-info-title">Datos de la sala</div>
            <div class="room-info-lines">{{seerData.name}}</div>
            <img *ngIf="seerData && seerData.photoURL" class="seer-img" [src]="seerData.photoURL" />
            <div class="room-info-lines">Nombre de la sala: {{room.nameRoom}} </div>
            <div class="room-info-lines session-date" *ngIf="room.showDate">
                Próxima emisión en directo: <br> {{room.dateRoom}} {{room.timeRoom}} <br> (Hora de España)
            </div>
            <div class="room-info-linessession-date" *ngIf="!room.showDate">
                Próxima emisión en directo: <br> Pendiente llegar al mínimo de <br>{{room.minimumNumberMessagesToStart}}
                mensajes grabados
            </div>
        </div>
    </div>
    <div class="room-message-container" *ngIf="seerRoomMessage || userRoomMessage">
        <div class="section-title" style="text-align: left; margin-bottom: 0px; margin-top: 10px;">
            <span class="sub-title">Mensaje en sala</span>
        </div>
        <div *ngIf="typeUser == '2' || typeUser == '3'" class="room-message">
            {{seerRoomMessage}}
        </div>

        <div *ngIf="typeUser == '1' || typeUser == '3'" class="room-message">
            {{userRoomMessage}}
        </div>
    </div>

    <div class="container">
        <div *ngIf="typeUser == '2'" class="user-info">
            <div class="welcome-user-logged">
                <i class="fas fa-user"></i> Bienvenido: {{seerData.name}}
            </div>
            <div class="users-pending-to-consult"> Usuarios pendientes de consulta: {{usersPendingToConsult}} </div>
            <div *ngIf="usersPendingToConsult != 0" class="time-estimated-finalize-session"> Tiempo restante estimado
                para
                finalizar la sesión: {{timeEstimatedFinalizeSession}} minutos</div>
        </div>
        <div *ngIf="typeUser != '2'" class="user-info">
            <div class="welcome-user-logged">
                <i class="fas fa-user"></i> Bienvenido: {{dataUser.name}}
            </div>

            <div class="user-credit">
                <i class="fas fa-coins"></i> Credito: {{dataUser.credit}}€
            </div>

            <div class="go-to-user-arrow" (click)="goToMyUser()">
                <i class="fas fa-arrow-circle-down"></i> Ir a mi usuario
            </div>

            <div class="help-section" (click)="startTour()">
                <i class="fas fa-question-circle"></i> Ayuda
            </div>

            <div class="redeem-code">
                <a (click)="openModalRedeemCode(modalRedeemCode)" class="template-btn btn-black text-align-center">
                    <i class="fas fa-ticket-alt"></i> ¿Tienes un código promocional?
                </a>
            </div>
        </div>

        <div *ngIf="typeUser == '3'" class="admin-controls">
            <div class="section-title" style="text-align: left; margin-bottom: 5px; margin-top: 10px;">
                <span class="sub-title">Acciones de administrador</span>
            </div>
            <div class="send-room-message">
                <a (click)="openModalSendMessageInRoom(modalSendMessageInRoom)"
                    class="template-btn btn-black text-align-center">
                    <i class="fas fa-message"></i> Enviar mensaje a la sala
                </a>
            </div>
        </div>


        <div *ngIf="typeUser == '2'" class="seer-controls">
            <a (click)="startStreamingConsult()" *ngIf="showStartStreamingConsultButton"
                class="template-btn btn-black text-align-center">
                <i class="fas fa-sign-in-alt"></i> Iniciar el streaming
            </a>

            <a (click)="stopStreamingConsult()" *ngIf="showStopStreamingConsultButton"
                class="template-btn btn-black text-align-center">
                <i class="fas fa-sign-in-alt"></i> Terminar el streaming
            </a>

            <a (click)="passNextUser()" *ngIf="showPassNextUserButton" class="template-btn btn-black text-align-center">
                <i class="fas fa-sign-in-alt"></i> Pasar al siguiente usuario
            </a>
            <a (click)="validateUserAudio()" *ngIf="showValidateAudioButton"
                class="template-btn btn-black text-align-center">
                <i class="far fa-check-circle"></i> Validar audio de usuario
            </a>
            <a (click)="invalidateUserAudio()" *ngIf="showValidateAudioButton"
                class="template-btn btn-black text-align-center">
                <i class="far fa-times-circle"></i> Rechazar audio de usuario
            </a>
            <a (click)="endQuery()" *ngIf="showFinishSessionButton" class="template-btn btn-black text-align-center">
                <i class="fas fa-door-closed"></i> Finalizar consulta
            </a>
            <div class="time-remaining-container" *ngIf="showCountdown">
                Tiempo restante de consulta: {{timeRemaining}}
            </div>

            <div *ngIf="showDataUserToConsultBox && typeUser == '2'" class="seer-user-data-to-consult">
                Datos de usuario a consultar:
                <div clas="user-data-to-consult-line">Nombre: {{nameToConsult}}</div>
                <div clas="user-data-to-consult-line">Fecha de nacimiento: {{birthdayToConsult | date: 'dd/MM/yyyy'}}
                    {{userYear}}</div>
                <div clas="user-data-to-consult-line">Comentarios: {{queryComments}}</div>
            </div>

        </div>

        <div class="online-users-container">
            <div class="section-title">
                <span class="sub-title">Usuarios en la sala</span>
            </div>

            <div class="online-users">
                <ng-container *ngFor="let userLiveRoomData of liveRoomData; let indexOfelement = index;">

                    <div class="user" [ngClass]="{'user-online': userLiveRoomData.isUserOnline == true,
          'user-offline': userLiveRoomData.isUserOnline == false,
          'user-block': userLiveRoomData.isInProcessToValidateAudio,
          'my-user': dataUser.id == userLiveRoomData.idUser && typeUser != '2' }">

                        <div class="user-state-label-container">
                            <div *ngIf="userLiveRoomData.isUserOnline" class="user-state-label user-online">online</div>
                            <div *ngIf="!userLiveRoomData.isUserOnline" class="user-state-label user-offline">offline
                            </div>
                            <div *ngIf="userLiveRoomData.isInProcessToValidateAudio"
                                class="user-state-label user-validating">En
                                validación</div>
                            <div *ngIf="userLiveRoomData.isUserCurrentConsulting"
                                class="user-state-label user-consulting">En
                                consulta
                            </div>
                        </div>
                        <img class="photo" [src]="getProfileImage(userLiveRoomData.imageUserURL)" />
                        <div class="user-data-container">
                            <div class="user-nickname">
                                {{userLiveRoomData.nickname}}
                            </div>
                            <div class="user-quantity-donate">
                                Cantidad aportada: {{userLiveRoomData.totalQuantityDonate}}€ <br>
                                Cantidad donada por un tercero: {{userLiveRoomData.totalQuantityDonateFromThird}}€
                                <ng-container
                                    *ngIf="dataUser.id != userLiveRoomData.idUser && userLiveRoomData.userHaveAudio && typeUser != '2' && !userLiveRoomData.isUserCurrentConsulting && !userLiveRoomData.isInProcessToValidateAudio">
                                    <div [attr.data-attr-user-id]="userLiveRoomData.idUser"
                                        class="star-rating-container star-rating-animation" fxLayout="row"
                                        fxLayoutAlign="start center">

                                        <div *ngFor="let star of userLiveRoomData.stars" #starsContainer
                                            [ngClass]="[star.class]" (click)="selectStar(userLiveRoomData, star.id)">
                                            <mat-icon id="star">{{star.icon}}</mat-icon>
                                        </div>

                                    </div>

                                </ng-container>
                            </div>

                            <div class="user-audio-invalid-message"
                                *ngIf="(dataUser.id == userLiveRoomData.idUser || typeUser == '2') && userLiveRoomData.userHaveAudio && !userLiveRoomData.isAudioValidated">
                                <i class="fas fa-exclamation-circle"></i> Con el mensaje enviado, no es posible realizar
                                tu consulta.
                                Inténtalo de nuevo
                            </div>
                            <div class="user-audio-invalid-message"
                                *ngIf="(dataUser.id == userLiveRoomData.idUser || typeUser == '2') && userLiveRoomData.totalQuantityDonate > 0 && !userLiveRoomData.userHaveAudio">
                                <i class="fas fa-exclamation-circle"></i> Debes grabar un mensaje de audio para poder
                                ser consultado
                            </div>
                            <div class="user-audio-invalid-message"
                                *ngIf="typeUser == '2' && userLiveRoomData.totalQuantityDonate == 0 && !userLiveRoomData.userHaveAudio">
                                <i class="fas fa-xmark"></i> El usuario todavía no ha realizado aportación
                            </div>
                            <div class="user-audio-invalid-message"
                                *ngIf="typeUser == '2' && userLiveRoomData.totalQuantityDonate > 0 && userLiveRoomData.userHaveAudio && userLiveRoomData.isAudioValidated">
                                <i class="fas fa-check"></i> El usuario tiene audio grabado
                            </div>

                            <div class="buttons-container" #buttonsContainer>
                                <ng-container *ngIf="dataUser.id == userLiveRoomData.idUser && typeUser != '2'">

                                    <a (click)="openModalDonate(modalDonate)"
                                        class="template-btn btn-black text-align-center btn-responsive btn-contribute">
                                        <i class="fas fa-donate"></i> Aportar
                                    </a>

                                    <a (click)="initiateRecording(userLiveRoomData, modalDataUserToConsult)"
                                        class="template-btn btn-black text-align-center btn-responsive btn-record-audio"
                                        *ngIf="!recording && !userLiveRoomData.userHaveAudio">
                                        <i class="fas fa-microphone-alt"></i> Grabar mensaje
                                    </a>
                                    <a (click)="stopRecording()"
                                        class="template-btn btn-black text-align-center btn-responsive"
                                        *ngIf="recording">
                                        <i class="fas fa-stop"></i> Parar grabación
                                    </a>

                                    <a (click)="removeRecord()"
                                        class="template-btn btn-black text-align-center btn-responsive"
                                        *ngIf="userLiveRoomData.userHaveAudio && (!userLiveRoomData.isInProcessToValidateAudio && !userLiveRoomData.isUserCurrentConsulting)">
                                        <i class="fas fa-eraser"></i> Eliminar audio
                                    </a>

                                    <a (click)="editQuery(modalDataUserToConsult)"
                                        class="template-btn btn-black text-align-center btn-responsive"
                                        *ngIf="userLiveRoomData.userHaveAudio">
                                        <i class="fas fa-pencil-alt"></i> Editar consulta
                                    </a>
                                </ng-container>

                                <div class="audio-controls-container" *ngIf="userLiveRoomData.userHaveAudio">
                                    <a (click)="playAudio(buttonsContainer, userLiveRoomData)"
                                        *ngIf="!userLiveRoomData.isPlayingAudio && typeUser != '2' || userLiveRoomData.isInProcessToValidateAudio && !userLiveRoomData.isPlayingAudio && typeUser == '2'"
                                        class="template-btn btn-black text-align-center btn-responsive">
                                        <i class="fas fa-play"></i>
                                    </a>

                                    <a (click)="pauseAudio(buttonsContainer, userLiveRoomData)"
                                        *ngIf="userLiveRoomData.isPlayingAudio && typeUser != '2' || userLiveRoomData.isInProcessToValidateAudio && userLiveRoomData.isPlayingAudio && typeUser == '2'"
                                        class="template-btn btn-black text-align-center btn-responsive">
                                        <i class="fas fa-pause"></i>
                                    </a>
                                </div>

                                <audio (ended)="audioEnded(userLiveRoomData)" class="player-voice-recorded"
                                    id="voice-recorded"
                                    *ngIf="userLiveRoomData.userHaveAudio && userLiveRoomData.userAudioPathSAS"
                                    controls="">
                                    <source [src]="sanitize(userLiveRoomData.userAudioPathSAS)" type="audio/wav">
                                </audio>

                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</section>


<ng-template #modalDonate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Realizar aportación
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">
                ×
            </span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label>
                    Cantidad aportada más alta: {{room.maxDonate}}€
                </label>
                <div class="input-group">
                    <input type="number" class="form-control input-other-quantity" [(ngModel)]='quantityInput'
                        [ngModelOptions]="{standalone: true}" placeholder="Introduce la cantidad (€)">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('OK')">
            Enviar
        </button>
    </div>
</ng-template>

<ng-template #modalDataUserToConsult let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Confirmación de opciones de consulta
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">
                ×
            </span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <h6 class="modal-title" id="modal-basic-title" style="margin: 15px 0;">
                    Datos de la persona a consultar
                </h6>
                <label>
                    <ng-switchery [(ngModel)]="ownQueryData" name="own-query-data-check"
                        (change)="toggleDataUserToConsultChange()"
                        onLabel="{{labelForToogleUserDataToConsult}}"></ng-switchery>
                </label>
                <div class="input-group">
                    <input type="text" class="form-control input-name-to-consult" [(ngModel)]='nameToConsult'
                        [ngModelOptions]="{standalone: true}" placeholder="Nombre y apellidos de persona a consultar">
                </div>
                <div class="input-group">
                    <input type="date" class="form-control input-birthday-to-consult" [(ngModel)]='birthdayToConsult'
                        [ngModelOptions]="{standalone: true}" placeholder="Fecha de nacimiento de persona a consultar">
                </div>
                <h6 class="modal-title" id="modal-basic-title" style="margin: 15px 0;">
                    Tipo de consulta
                </h6>
                <label>
                    <ng-switchery [(ngModel)]="allowQueryOffline" name="allow-query-offline-check"
                        onLabel="{{labelForToogleAllowQueryOffline}}"></ng-switchery>
                </label>
                <h6 class="modal-title" id="modal-basic-title" style="margin: 15px 0;">
                    Comentarios
                </h6>
                <textarea maxlength="250" rows="4" cols="50" [(ngModel)]='queryComments'
                    placeholder="Máximo 250 caractéres..." class="query-comments-textarea"></textarea>

                <div class="audio-legal-terms mt20">
                    Al enviar el audio aceptas nuestras <a href="/hiring-terms-advice">terminos de contratación</a>
                    <br>y el <a href="/personal-data-advice">consentimiento de tratamiento de datos personales.</a>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('OK')">
            Enviar
        </button>
    </div>
</ng-template>

<ng-template #modalRedeemCode let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Validación de código promocional
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">
                ×
            </span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <div class="input-group">
                    <input type="text" class="form-control input-name-to-consult" [(ngModel)]='promotionalCode'
                        [ngModelOptions]="{standalone: true}" placeholder="Introduce el código promocional">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('OK')">
            Enviar
        </button>
    </div>
</ng-template>

<ng-template #modalSendMessageInRoom let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Enviar mensaje a la sala
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">
                ×
            </span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <mat-radio-group name="message-type" [(ngModel)]="messageTypeSelected">
                <mat-radio-button value="user" style="margin: 10px">
                    Usuario
                </mat-radio-button>
                <mat-radio-button value="seer" style="margin: 10px">
                    Maestro
                </mat-radio-button>
            </mat-radio-group>

            <div class="form-group">
                <div class="input-group">
                    <textarea maxlength="250" rows="4" cols="50" [ngModelOptions]="{standalone: true}" [(ngModel)]='roomMessage'
                        placeholder="Máximo 250 caractéres..."></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('OK')">
            Enviar
        </button>
    </div>
</ng-template>

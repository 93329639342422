import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { SeerModel } from '../models/seers/seers.model'
import { SeersBackendService } from '../services/seers-backend.service'

@Injectable()

export class SeerManager {

    private seersAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _seersBackendService: SeersBackendService
    ) { }

    ngOnDestroy() {
        if (this.seersAPI != null) {
            this.seersAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    loginNativeSeer(seerDataToLogin: any) {
        return new Promise((resolve) => {

            this.seersAPI = this._seersBackendService.loginNativeSeer(seerDataToLogin)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Seer Login native success")
                        let loginNativeSeerData = response.user_data

                        resolve(loginNativeSeerData)
                    }
                    else if (response.code == 201) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Usuario/contraseña incorrectos',
                            showConfirmButton: false,
                            timer: 4000
                        })
                        resolve(false)
                    }
                    else {
                        console.log("Error in seer login native")
                        resolve(false)
                    }

                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }
    getSeerById(idSeer: string) {
        return new Promise((resolve) => {
            this.seersAPI = this._seersBackendService.getSeerById(idSeer)
                .pipe(takeUntil(this.destroy$))
                .subscribe((seerData: SeerModel) => {
                    console.log("Get seer data retrieved success")
                    resolve(seerData)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });

        })
    }

    getAllSeers() {
        return new Promise((resolve) => {
            this.seersAPI = this._seersBackendService.getAllSeers()
                .pipe(takeUntil(this.destroy$))
                .subscribe((seers: SeerModel[]) => {
                    resolve(seers)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    deleteSeer(seer: { id: string; name: string }) {
        return new Promise(resolve => {
            this.seersAPI = this._seersBackendService.deleteSeer(seer.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {

                        Swal.fire({
                            icon: 'success',
                            title: "El vidente " + seer.name + " se ha dado de baja correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    registerSeer(seer: any) {
        return new Promise(resolve => {
            this.seersAPI = this._seersBackendService.registerSeer(seer)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("New Seer is Created Successfully")

                        Swal.fire({
                            icon: 'success',
                            title: 'Nuevo vidente dado de alta correctamente',
                            showConfirmButton: false,
                            timer: 4000
                        })

                        let userData = response.user_data

                        resolve(userData)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updateSeer(idSeer: string, seerData: any) {
        return new Promise(resolve => {
            this.seersAPI = this._seersBackendService.updateSeer(idSeer, seerData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Seer Updated Successfully")

                        resolve(response.user_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }
}
import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class SeersBackendService{
	public httpOptions!: HttpHeaders
	private _seersEndPoint: string

	constructor(
		public _httpClient: HttpClient
	){
		this._seersEndPoint = environment.APIEndPointBackend + environment.Seers
	}

  /* REGISTER NEW SEER */
	public registerSeer(seerUserData: any): Observable<any>{

    return this._httpClient.post(this._seersEndPoint, seerUserData)
      .pipe(
             map((data: any) => {
               return data;
             }), 
             catchError( error => {
               console.log("Error when register new seer: ", error.error)
               return error 
             })
          )
	}

  /* GET ALL SEERS */
  public getAllSeers(): Observable<any>{

    return this._httpClient.get(this._seersEndPoint)
      .pipe(
             map((data: any) => {
               return data;
             }), 
             catchError( error => {
               console.log("Error when get all seers: ", error.error)
               return error 
             })
          )
  }

  /* LOGIN NATIVE SEER */
  public loginNativeSeer(seerData: any): Observable<any>{
    return this._httpClient.post(this._seersEndPoint + environment.Login, seerData)
      .pipe(
             map((data: any) => {
               return data;
             }), 
             catchError( error => {
               console.log("Seer user not registered: ", error.error)
               return error 
             })
          )
  }

  /* DELETE SEER AND ROOMS ASSOCIATED */
  public deleteSeer(seerId: string): Observable<any>{
    return this._httpClient.delete(this._seersEndPoint + seerId)
      .pipe(
             map((data: any) => {
               return data;
             }), 
             catchError( error => {
               console.log("Delete seer and Rooms asocciated error: ", error.error)
               return error 
             })
          )
  }

  /* GET SEER BY ID */
  public getSeerById(seerId: string): Observable<any>{
    return this._httpClient.get(this._seersEndPoint + seerId)
      .pipe(
             map((data: any) => {
               return data;
             }), 
             catchError( error => {
               console.log("Seer retrieve problem: ", error.error)
               return error 
             })
          )
  }

   /* UPDATE TO USER */
   public updateSeer(id: string, seerData: any): Observable<any> {
    return this._httpClient.put(this._seersEndPoint + id, seerData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Seer not updated: ", error.error)
          return error
        })
      )
  }
}
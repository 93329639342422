import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators';
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class AzureBackendService {
    public httpOptions!: HttpHeaders
    private _azureEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._azureEndPoint = environment.APIEndPointBackend + environment.Azure
    }

    /* GET SAS Token */
    public getSASToken(azureGetSASTokenData: { container: string; blobName: string; permissions: string; }): Observable<any> {

        return this._httpClient.post(this._azureEndPoint + environment.AzureGetSASToken, azureGetSASTokenData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get SAS token: ", error)
                    return error
                })
            )
    }

}
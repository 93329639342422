import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { UserModel } from '../models/users/user.model'
import { UsersBackendService } from '../services/users-backend.service'

@Injectable()

export class UserManager {

    private usersAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _cookieService: CookieService,
        private _usersBackendService: UsersBackendService
    ) { }

    ngOnDestroy() {
        if (this.usersAPI != null) {
            this.usersAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    registerSocialUser(socialUserData: any){
        return new Promise((resolve) => {
            
            this.usersAPI = this._usersBackendService.registerSocialUser(socialUserData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Register social user success")
                        let socialUserData = response.user_data

                        resolve(socialUserData)
                    }
                    else{
                        console.log("Error in register social user")
                        resolve(false)
                    }
                   
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    
    registerNativeUser(userDataToRegister: any){
        return new Promise((resolve) => {
            
            this.usersAPI = this._usersBackendService.registerNativeUser(userDataToRegister)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {

                    if (response.code == 200) {
                        Swal.fire({
                          icon: 'success',
                          title: 'Usuario registrado correctamente',
                          showConfirmButton: false,
                          timer: 4000
                        })
                        
                        let userData = response.user_data

                        resolve(userData)
                      }
              
                      else if (response.code == 201) {
                        Swal.fire({
                          icon: 'error',
                          title: 'El '.concat(response.exists).concat(" ya está dado de alta en la plataforma."),
                          showConfirmButton: false,
                          timer: 4000
                        })

                        resolve(false)
                      }
              
                      else {
                        Swal.fire({
                          icon: 'error',
                          title: 'Ocurrió un error registrando al usuario. Inténtalo más tarde',
                          showConfirmButton: false,
                          timer: 4000
                        })

                        resolve(false)
                      }
                   
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    getSocialUser(userId: any){
        return new Promise((resolve) => {
            
            this.usersAPI = this._usersBackendService.getSocialUser(userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Get social user success")
                        let socialUserData = response.user_data

                        resolve(socialUserData)
                    }
                    else{
                        console.log("Error in get social user")
                        resolve(false)
                    }
                   
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    loginNativeUser(userDataToLogin: any){
        return new Promise((resolve) => {
            
            this.usersAPI = this._usersBackendService.loginNativeUser(userDataToLogin)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {

                    if (response.code == 200) {
                        console.log("User Login native success")
                        let loginNativeUserData = response.user_data

                        resolve(loginNativeUserData)
                    }
                    else if(response.code == 201){
                        resolve(false)
                    }
                    else{
                        console.log("Error in user login native")
                        resolve(false)
                    }
                   
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    getUserData(idUser: string, typeUser: string, own = true) {
        return new Promise((resolve) => {
            this.usersAPI = this._usersBackendService.getUserData(idUser)
                .pipe(takeUntil(this.destroy$))
                .subscribe((dataUser: UserModel) => {
                    console.log("Get user data retrieved success")

                    if ((typeUser != "2" && own) && dataUser.credit < 5) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Su crédito es inferior a 5€',
                            showConfirmButton: false,
                            timer: 4000
                        })
                    }

                    if (typeUser != "2" && own) {
                        this._cookieService.set("User", JSON.stringify(dataUser))
                    }

                    resolve(dataUser)
                },
                    error => {

                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updateNickname(idUser: string, userData: any) {
        return new Promise((resolve) => {
            this.usersAPI = this._usersBackendService.updateNicknameUser(idUser, userData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("User Nickname Updated Successfully")
                        resolve(true)
                    }

                    else if (response.code == 201) {
                        Swal.fire({
                            icon: 'error',
                            title: 'El '.concat(response.exists).concat(" ya está dado de alta en la plataforma."),
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(false)
                    }

                    
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    updateUser(idUser: string, userData: any) {
        return new Promise((resolve) => {
            this.usersAPI = this._usersBackendService.updateUser(idUser, userData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("User Updated Successfully")

                        resolve(response.user_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updateUserCredit(idUser: string, userData: any) {
        return new Promise((resolve) => {
            this.usersAPI = this._usersBackendService.updateUserCredit(idUser, userData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Credit Updated Successfully")
                        
                        resolve(response.user_data)
                    }

                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

}


import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LiveRoomModel } from '../models/live-room/live-room.model'
import { LiveRoomBackendService } from '../services/live-room-backend.service'

@Injectable()

export class LiveRoomManager {

    private liveRoomAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _cookieService: CookieService,
        private _liveRoomBackendService: LiveRoomBackendService
    ) { }

    ngOnDestroy() {
        if (this.liveRoomAPI != null) {
            this.liveRoomAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getLiveRoomUser(idLiveRoom: string) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.getLiveRoomUser(
                idLiveRoom)
                .pipe(takeUntil(this.destroy$))
                .subscribe((liveRoomUser: any) => {
                    console.log("Get Live Room data retrieved success")
                    resolve(liveRoomUser.user_data)
                },
            error => {
                console.log("Response not success: ", <any>error)
                resolve(false)
            })

        })
    }

    saveUserDataToLiveRoom(idRoom: any, idUser: string) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.addUserToLiveRoom({
                idRoom: idRoom,
                idUser: idUser
            })
                .pipe(takeUntil(this.destroy$))
                .subscribe((liveRoomUserData: LiveRoomModel) => {

                    console.log("User Data to Live Room save success")

                    this._cookieService.set("UserLiveRoom", liveRoomUserData.id)

                    liveRoomUserData.isUserOnline = true
                    this.updateLiveRoomUser(liveRoomUserData)

                    resolve(liveRoomUserData)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    donateFromThird(dataToSend: { idRoom: any; idUserToDonate: any; idUserDonate: string; quantityDonate: number; totalQuantityDonate: number; totalQuantityDonateFromThird: number; valueDonate: any }) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.donateFromThird(
                dataToSend)
                .pipe(takeUntil(this.destroy$))
                .subscribe((liveRoomUserData: any) => {
                    console.log("Donate From third make success")
                    resolve(liveRoomUserData.user_data)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updateLiveRoomUser(userDataToUpdate: LiveRoomModel) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.updateLiveRoomUser(
                userDataToUpdate.id, userDataToUpdate)
                .pipe(takeUntil(this.destroy$))
                .subscribe((liveRoomUserData: any) => {
                    console.log("Live Room user updated success")
                    resolve(liveRoomUserData)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    getAllUsersForLiveRoom(idRoom: string) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.getAllUsersForLiveRoom(idRoom)
                .pipe(takeUntil(this.destroy$))
                .subscribe((liveRoomData: LiveRoomModel[]) => {
                    console.log("Get all users for live room retrieved success")

                    resolve(liveRoomData)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updateDonateLiveRoomUser(liveRoomdataToEdit: LiveRoomModel) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.updateDonateLiveRoomUser(
                liveRoomdataToEdit.id, liveRoomdataToEdit)
                .pipe(takeUntil(this.destroy$))
                .subscribe((liveRoomUserData: any) => {

                    console.log("User Data to Live Room update success")
                    resolve(liveRoomUserData.liveRoomUserData)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    getLiveRoomUserByIdRoom(idUser: string, idRoom: any) {
        return new Promise((resolve) => {
            this.liveRoomAPI = this._liveRoomBackendService.getLiveRoomUserByIdRoom(idUser, idRoom)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 201) {
                        console.log("User not exists in Room")
                        resolve(false)
                    }
                    else if (response.code == 200) {
                        console.log("User in Room retrieved success")

                        resolve(response.user_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }


}


import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class PromotionsBackendService {
    public httpOptions!: HttpHeaders
    private _promotionsEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._promotionsEndPoint = environment.APIEndPointBackend + environment.Promotions
    }

    /* CREATE PROMOTION */
    public createPromotion(promotion: any): Observable<any> {

        return this._httpClient.post(this._promotionsEndPoint, promotion)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when create promotion: ", error)
                    return error
                })
            )
    }

    /* GET PROMOTIONS */
    public getPromotions(): Observable<any> {

        return this._httpClient.get(this._promotionsEndPoint)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get promotions: ", error)
                    return error
                })
            )
    }

    /* DELETE PROMOTION BY ID */
    public deletePromotionById(promotionId: any): Observable<any> {

        return this._httpClient.request("delete", this._promotionsEndPoint, { body: { id: promotionId } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when delete promotion: ", error.error)
                    return error
                })
            )
    }

    // UPDATE PROMOTION 
    public updatePromotion(idPromotion: string, promotionData: any): Observable<any> {

        return this._httpClient.put(this._promotionsEndPoint + idPromotion, promotionData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when updating promotion: ", error)
                    return error
                })
            )
    }

    // GET PROMOTION BY NAME
    public getPromotionByName(promotionName: string): Observable<any> {

        return this._httpClient.get(this._promotionsEndPoint + environment.GetPromotionByName + promotionName)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get promotion Name: ", error)
                    return error
                })
            )
    }

}
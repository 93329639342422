import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { Subject } from 'rxjs'
import { ActivatedRoute, Router } from "@angular/router"

import { DomSanitizer } from '@angular/platform-browser'

import * as RecordRTC from 'recordrtc'

import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'

import { environment } from '../../../../environments/environment'

import Swal from 'sweetalert2'
import getBlobDuration from 'get-blob-duration'

/* Models */
import { SeerModel } from '../../../models/seers/seers.model'
import { DonationModel } from '../../../models/donation/donation.model'
import { PromotionsModel } from 'src/app/models/promotions/promotions.model'
import { LiveRoomModel } from '../../../models/live-room/live-room.model'
import { RoomModel } from '../../../models/rooms/room.model'
import { UserModel } from '../../../models/users/user.model'

/* Managers */
import { UserManager } from 'src/app/managers/user.manager'
import { RoomManager } from 'src/app/managers/room.manager'
import { LiveRoomManager } from 'src/app/managers/live-room.manager'
import { SeerManager } from 'src/app/managers/seer.manager'
import { DonationManager } from 'src/app/managers/donation.manager'
import { PromotionManager } from 'src/app/managers/promotion.manager'
import { RedeemedPromotionsManager } from 'src/app/managers/redeemed-promotions.manager'
import { SessionsManager } from 'src/app/managers/sessions.manager'
import { SessionCreditConsolidatedManager } from 'src/app/managers/session-credit-consolidated.manager'

/* Services */
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service'
import { CookieService } from 'ngx-cookie-service'
import { SocketProviderConnect } from '../../../services/socket/web.socket.service'
import { LoadingService } from '../../../services/loading/loading.service'
import { Utils } from '../../../utilities/utils'

// Guided Tour
import { GuidedTour, Orientation } from 'ngx-guided-tour'
import { GuidedTourService } from 'ngx-guided-tour'
import { AzureManager } from 'src/app/managers/azure.manager'

interface VideoElement {
  muted: boolean;
  srcObject: MediaStream;
  userId: string;
}

@Component({
  selector: 'app-online-session',
  templateUrl: './online-session.component.html',
  styleUrls: ['./online-session.component.scss']
})

export class OnlineSessionComponent implements OnInit {

  public liveRoomData: LiveRoomModel[]
  public currentLiveRoomUserData: LiveRoomModel
  public idUser: string
  public dataUser: UserModel
  public seerData: SeerModel = new SeerModel()
  public room: RoomModel
  public stars: any
  public recording = false
  public quantityInput: number
  public showValidateAudioButton = false
  public showStartStreamingConsultButton = true
  public showStopStreamingConsultButton = false
  public showPassNextUserButton = true
  public showFinishSessionButton = false
  public showCountdown = false
  public messages = []
  public timeRemaining: string | undefined
  public youTubeStreamingUrl: any
  public typeUser: string
  public modalDataUserToConsult!: { disableClose: boolean }
  public nameToConsult: string
  public birthdayToConsult: any
  public ownQueryData = true
  public allowQueryOffline = true
  public audioConditions = false
  public labelForToogleUserDataToConsult = "Son mis datos de usuario"
  public labelForToogleAllowQueryOffline = "¿Permitir consulta en estado offline?"
  public showDataUserToConsultBox = false
  public usersPendingToConsult = 0
  public timeEstimatedFinalizeSession = 0
  public promotionalCode: string
  public roomMessage: string
  public messageTypeSelected: string | undefined
  public userYear: string
  public seerRoomMessage: string
  public userRoomMessage: string
  public queryComments: string
  public siteDomain: string
  private storageAccountImagesPath: string
  private intervalQueryRemaining: any
  private userCurrentConsultingOrValidated: LiveRoomModel
  private record: any
  private idRoom: string
  private isCurrentPlayingAudio = false
  private socketService: SocketProviderConnect
  private uploadConfig: UploadConfig
  private azureAPI: Subscription
  private destroy$: Subject<boolean> = new Subject<boolean>()
  private isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  private streamingInterval: any
  private videoStream: any
  private context: any | null
  private localStream: MediaStream;
  private peerConnection: RTCPeerConnection;
  private peerConnectionRemote: RTCPeerConnection;
  private answerQueue: any = []

  public video!: VideoElement
  public isMuted: boolean
  public inStreaming: boolean
  public pc: any


  @ViewChild('canvasPreview', { static: true })
  canvas: ElementRef<HTMLCanvasElement>

  @ViewChild('localVideo', { static: true })
  localVideo: ElementRef

  @ViewChild('remoteVideo', { static: true })
  remoteVideo: ElementRef

  @ViewChild('videoContainer', { static: true })
  videoContainer: ElementRef

  public dashboardTour: GuidedTour = {
    tourId: 'online-session-tour',
    useOrb: false,
    steps: [
      {
        title: 'Bienvenido a la sesión',
        selector: '.room-info',
        content: 'Aquí, podrás consultar los detalles del maestro espiritual',
        orientation: Orientation.Bottom
      },
      {
        title: 'En esta sección, puedes consultar tus datos de usuario',
        selector: '.user-info',
        content: '',
        orientation: Orientation.Top
      },
      {
        title: 'Introduce un código promocional',
        selector: '.redeem-code',
        content: 'Si dispones de un código promocional, pulsa sobre este botón para añadirlo y sumar crédito a tu cartera. Si es la primera vez que accedes utiliza WELCOMETOT para sumar 5€ a tu crédito',
        orientation: Orientation.Top
      },
      {
        title: 'Vídeo de la sala en directo',
        selector: '.streaming-video-frame',
        content: 'El día de la sala en directo, se podrá ver en esta ventana al maestro espiritual respondiendo a las consultas',
        orientation: Orientation.Top
      },
      {
        title: 'Instrucciones para ser consultado',
        content: 'A continuación, se muestran los pasos que se deben seguir para poder ser consultado',
      },
      {
        title: 'Realiza una donación para poder grabar un audio',
        selector: '.btn-contribute',
        content: 'Cada sala, tiene una cantidad mínima de donación para poder grabar un audio',
        orientation: Orientation.TopLeft
      },
      {
        title: 'Si aparece esta etiqueta, significa que aún no has grabado audio',
        selector: '.user-audio-invalid-message',
        content: 'Sin audio grabado, el maestro, no te podra consultar. Lo siguiente que debemos hacer es grabar un audio',
        orientation: Orientation.TopLeft
      },
      {
        title: 'Pulsar para comenzar a grabar audio',
        selector: '.btn-record-audio',
        content: 'Una vez pulsado, se empezará a grabar y el icono de play, se reemplazá por un stop que debemos pulsar al finalizar la grabación',
        orientation: Orientation.TopLeft
      },
      {
        title: 'Realizar donación',
        selector: '.star',
        content: 'Si al escuchar el mensaje de otro usuario, deseas contribuir a que sea consultado, puedes realizar una donación a este usuario en forma de estrellas. Cuantas más estrellas, más saldo donas. Antes de donar, te pedirá confirmación',
        orientation: Orientation.TopLeft
      },
      {
        title: 'Escalado en el listado de usuarios para ser consultados',
        content: 'La sesión, está programada para que vayas ascendiendo posiciones dependiendo de la cantidad aportada (tuya propia o de donaciones de terceros). <br> Cuanto más arriba estes, antes serás consultado',
      },
      {
        title: 'Este es tu usario y posición en la sala',
        selector: '.my-user',
        content: 'Disfruta de la sala en directo de TOT Online',
        orientation: Orientation.TopLeft
      },
    ]
  };

  public myUserTour: GuidedTour = {
    tourId: 'online-session-tour',
    useOrb: false,
    steps: [
      {
        title: 'Este es tu usario y posición en la sala.',
        selector: '.my-user',
        content: 'Disfruta de la sala en directo de TOT Online.',
        orientation: Orientation.TopLeft
      },
    ]
  };

  constructor(
    private _domSanitizer: DomSanitizer,
    private _modalService: NgbModal,
    private _blobService: BlobService,
    private _cookieService: CookieService,
    private _loadingService: LoadingService,
    private _router: Router,
    private _utils: Utils,
    private _userManager: UserManager,
    private _roomManager: RoomManager,
    private _liveRoomManager: LiveRoomManager,
    private _seerManager: SeerManager,
    private _donationManager: DonationManager,
    private _promotionManager: PromotionManager,
    private _redeemPromotionsManager: RedeemedPromotionsManager,
    private _sessionsManager: SessionsManager,
    private _sessionCreditConsolidatedManager: SessionCreditConsolidatedManager,
    private _guidedTourService: GuidedTourService,
    private _azureManager: AzureManager,
    private _activatedRoute: ActivatedRoute

  ) { }

  ngOnInit() {

    if ( !this._utils.IsJsonString(this._cookieService.get('User')) || !this._utils.IsJsonString(this._cookieService.get("Type"))) {

      Swal.fire({
        icon: 'error',
        title: 'Para entrar a la sala, debes haber iniciado sesión',
        showConfirmButton: false,
        timer: 4000
      })

      window.location.href = environment.SiteDomain + '/login'

      return
    }

    this.idRoom = JSON.parse(this._cookieService.get("Room"))
    this.dataUser = JSON.parse(this._cookieService.get('User'))
    this.typeUser = JSON.parse(this._cookieService.get("Type"))

    this._loadingService.show()

    this.siteDomain = environment.SiteDomain

    this.storageAccountImagesPath = this._utils.getAzureStorageAccountPathForImages()

    const configuration = {
      iceServers: [
        { urls: ['stun:freestun.net:3479',
                'stun:freestun.net:5350'] } // 'stun:stun1.l.google.com:19302' modificado y añadido otro server stun
      ],
    }
    

    this.peerConnection = new RTCPeerConnection(configuration)

    if (this.typeUser == "2") {
      this._cookieService.set("UserLiveRoom", this.dataUser.id)

      this.remoteVideo.nativeElement.remove()

      this.initData()
    }

    else if (this.typeUser != "2") {

      if (this.dataUser.credit < 5) {
        Swal.fire({
          icon: 'warning',
          title: 'Su crédito es inferior a 5€',
          showConfirmButton: false,
          timer: 4000
        })
      }

      this.localVideo.nativeElement.remove()

      this.initData()

    }
  }

  ngOnDestroy() {

    if (this.azureAPI != null) {
      this.azureAPI.unsubscribe()
    }

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  selectStar(userLiveRoomData: { canDonate: any }, value: any) {
    if (!userLiveRoomData.canDonate) {
      Swal.fire({
        icon: 'error',
        title: 'No puedes donar de nuevo a este usuario',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    this.donateFromThird(userLiveRoomData, value)
  }

  sanitize(url: string) {
    return this._domSanitizer.bypassSecurityTrustUrl(url);
  }

  sanitizeResourceUrl(url: string) {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getAllUsersForLiveRoom() {
    this._liveRoomManager.getAllUsersForLiveRoom(this.idRoom).then(result => {
      if (result) {
        if (this.isCurrentPlayingAudio) {
          console.log("Data not refreshed because user is playing audio")
          return
        }

        console.log("Get all users for live room retrieved success")
        this.liveRoomData = result as LiveRoomModel[]

        this.liveRoomData.sort(({ isUserCurrentConsulting: A }, { isUserCurrentConsulting: B }) => Number(B) - Number(A))
        this.liveRoomData.sort(({ isInProcessToValidateAudio: A }, { isInProcessToValidateAudio: B }) => Number(B) - Number(A))

        this.usersPendingToConsult = 0

        this.liveRoomData.forEach(currentLiveRoomData => {
          currentLiveRoomData.stars = this.initializeStarsArray()
          currentLiveRoomData.canDonate = true

          if ((currentLiveRoomData.imageUser != null && currentLiveRoomData.imageUser != "" && !currentLiveRoomData.imageUser.includes("google"))) {
            currentLiveRoomData.imageUserURL = this.storageAccountImagesPath.concat(currentLiveRoomData.imageUser)
          }

          if (this.typeUser != "2" && currentLiveRoomData.receivedDonationFromThird) {
            this.getUserDonation(currentLiveRoomData)
          }

          if (this.typeUser == "2") {
            if (currentLiveRoomData.userHaveAudio) {
              this.usersPendingToConsult++
            }

            if (currentLiveRoomData.isUserCurrentConsulting) {
              this.showPassNextUserButton = false
              this.showFinishSessionButton = true
              this.showDataUserToConsultBox = true
              this.nameToConsult = currentLiveRoomData.nameToConsult
              this.birthdayToConsult = currentLiveRoomData.birthdayToConsult
              this.queryComments = currentLiveRoomData.queryComments

              this.userCurrentConsultingOrValidated = currentLiveRoomData
            }

            if (this.usersPendingToConsult != 0) {
              this.timeEstimatedFinalizeSession = (this.room.timeToConsult / 60) * this.usersPendingToConsult
            }
          }
        })

        this._loadingService.hide()
      }
    })
  }


  updateLiveRoomUser(updateView = false, userDataToUpdate = this.currentLiveRoomUserData, own = true) {
    this._liveRoomManager.updateLiveRoomUser(userDataToUpdate).then(result => {
      if (result) {

        if (own) {
          this.currentLiveRoomUserData = result as LiveRoomModel
        }

        if (updateView) {
          this.getAllUsersForLiveRoom()
          this.socketService.emitEvent("doing-update-users")
        }
      }
    })
  }

  getProfileImage(imageUser: string | null | undefined) {
    if (imageUser == "" || imageUser == undefined || imageUser == null) {
      return "assets/img/no-avatar.png"
    }

    return imageUser
  }

  initiateRecording(currentLiveRoomUser: LiveRoomModel, modalDataUserToConsult: any) {

    let permissionsAreGranted = false

    this._utils.getIfBrowserPermissionsAreGranted({ audio: true, video: false }).then((result: any) => {
      if (!result) {
        Swal.fire({
          icon: 'warning',
          title: 'Para grabar un audio, debes haber aceptado el permiso de micrófono que te muestra el navegador. Si no consigue activar el permiso, envia un email a contacto@totonline.es',
          showConfirmButton: true,
        })

        return
      }
      else {
        this.modalDataUserToConsult = modalDataUserToConsult

        let userQuantityDonate = (currentLiveRoomUser.totalQuantityDonate + currentLiveRoomUser.totalQuantityDonateFromThird)

        if (this.room.minimumAportation > userQuantityDonate) {
          Swal.fire({
            icon: 'error',
            title: 'Para grabar un audio, debes haber aportado más que la cantidad mínima de la sala que son: '.concat(this.room.minimumAportation.toString()).concat("€"),
            showConfirmButton: false,
            timer: 4000
          })

          return
        }

        this.recording = true
        currentLiveRoomUser.isPlayingAudio = true
        currentLiveRoomUser.userAudioPath = ""

        let mediaConstraints = {
          video: false,
          audio: true
        };

        navigator.mediaDevices.getUserMedia(mediaConstraints)
          .then(this.successCallback.bind(this),
            this.errorCallback.bind(this));
      }
    })

  }

  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this))
  }

  processRecording(blob: any) {
    this.uploadAudioBlob(blob)
  }

  removeRecord() {
    Swal.fire({
      title: 'Estas seguro de que quieres eliminar tu audio actual?',
      text: "Está acción, no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, grabar un nuevo audio',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.addOrQuitUsersWithAudioUpload(false).then(result => {

          if (result != null || result != undefined) {
            console.log("entra result remove")
            this._roomManager.updateRoom(this.idRoom, { usersWithAudioUpload: result }).then(result => {

              if (result) {
                Swal.fire(
                  'Audio eliminado!',
                  'Tu audio ha sido eliminado',
                  'success'
                )

                this.currentLiveRoomUserData.userHaveAudio = false
                this.currentLiveRoomUserData.userAudioPath = ""
                this.isCurrentPlayingAudio = false

                this.updateLiveRoomUser(true)

                this.socketService.emitEvent("doing-less-user-to-consult")
              }
            })
          }
        })

      }
    })

  }

  editQuery(modalDataUserToConsult: any) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    };

    this.nameToConsult = this.currentLiveRoomUserData.nameToConsult
    this.birthdayToConsult = this.currentLiveRoomUserData.birthdayToConsult
    this.allowQueryOffline = this.currentLiveRoomUserData.allowQueryOffline
    this.ownQueryData = this.currentLiveRoomUserData.ownQueryData
    this.queryComments = this.currentLiveRoomUserData.queryComments

    this._modalService.open(modalDataUserToConsult, ngbModalOptions).result.then((result) => {

      if (result == "OK") {
        this.currentLiveRoomUserData.nameToConsult = this.nameToConsult
        this.currentLiveRoomUserData.birthdayToConsult = this.birthdayToConsult
        this.currentLiveRoomUserData.allowQueryOffline = this.allowQueryOffline
        this.currentLiveRoomUserData.ownQueryData = this.ownQueryData
        this.currentLiveRoomUserData.queryComments = this.queryComments

        this.updateLiveRoomUser(true, this.currentLiveRoomUserData, true)
      }
    })
  }

  playAudio(buttonsContainer: any, currentLiveRoomUser: LiveRoomModel) {
    this.reproduceBlob(buttonsContainer, currentLiveRoomUser, "r")
  }

  pauseAudio(buttonsContainer: any, currentLiveRoomUser: LiveRoomModel) {
    let voiceRecordedElement = <HTMLAudioElement>buttonsContainer.querySelectorAll("#voice-recorded")[0]
    voiceRecordedElement.pause()
    currentLiveRoomUser.isPlayingAudio = false
    this.isCurrentPlayingAudio = false
  }

  audioEnded(currentLiveRoomUser: LiveRoomModel) {
    currentLiveRoomUser.isPlayingAudio = false
    this.isCurrentPlayingAudio = false
  }

  successCallback(stream: any) {
    var options: any = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1
    };

    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();

  }

  errorCallback(error: any) {
  }

  uploadAudioBlob(audioFile: File) {
    console.log("al subir: ", this.currentLiveRoomUserData)
    let percentToShow = 0

    const Config: UploadParams = {
      sas: environment.AzureSASTokenAudio,
      storageAccount: environment.AzureStorageAccountName,
      containerName: environment.BlobContainerAudio
    }

    if (audioFile !== null) {
      let timerInterval
      Swal.fire({
        title: 'Guardando...',
        html: 'Guardando mensaje de audio',
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      getBlobDuration(audioFile).then(duration => {

        let audioDuration = Math.floor(duration)

        const fileName = this.idRoom + "|" + this.dataUser.id + ".wav"
        const baseUrl = this._blobService.generateBlobUrl(Config, fileName)

        this.uploadConfig = {
          baseUrl: baseUrl,
          sasToken: Config.sas,
          blockSize: 1024 * 64,
          file: audioFile,
          complete: () => {
            console.log("entra en fin audio subido")
            Swal.close()

            this.nameToConsult = this.dataUser.name
            this.birthdayToConsult = this.dataUser.birthday

            this.currentLiveRoomUserData.userHaveAudio = true
            this.currentLiveRoomUserData.userAudioPath = fileName

            let ngbModalOptions: NgbModalOptions = {
              backdrop: 'static',
              keyboard: false,
              ariaLabelledBy: 'modal-basic-title'
            };

            this._modalService.open(this.modalDataUserToConsult, ngbModalOptions).result.then((result) => {

              this.modalDataUserToConsult.disableClose = true

              this.currentLiveRoomUserData.nameToConsult = this.nameToConsult
              this.currentLiveRoomUserData.birthdayToConsult = this.birthdayToConsult
              this.currentLiveRoomUserData.allowQueryOffline = this.allowQueryOffline
              this.currentLiveRoomUserData.ownQueryData = this.ownQueryData
              this.currentLiveRoomUserData.queryComments = this.queryComments
              this.currentLiveRoomUserData.audioDuration = audioDuration

              this.updateLiveRoomUser(true)

              if (result == "OK") {
                this.addOrQuitUsersWithAudioUpload(true).then(result => {
                  if (result) {
                    this._roomManager.updateRoom(this.idRoom, { usersWithAudioUpload: result }).then(result => {
                      if (result) {
                        Swal.fire({
                          icon: 'success',
                          title: 'Audio guardado correctamente',
                          showConfirmButton: false,
                          timer: 4000
                        })
                      }
                    })
                  }
                })
              }

              this.socketService.emitEvent("doing-new-user-to-consult")
            })
          },
          error: (err) => {
            console.log('Error:', err);
            Swal.fire({
              icon: 'error',
              title: 'Ocurrió algun error guardando el mensaje de audio. Inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es',
              showConfirmButton: false,
              timer: 4000
            })
          },
          progress: (percent) => {
            percentToShow = percent
          }
        };
        this._blobService.upload(this.uploadConfig);
      })
    }
  }

  checkConditions(modal: { close: (arg0: string) => void }) {
    if (!this.audioConditions) {
      Swal.fire({
        icon: 'error',
        title: 'Debes aceptar el consentimiento para el tratamiento de datos personales',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    modal.close('OK')
  }

  openModalRedeemCode(modalRedeemCode: any) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    };

    this._modalService.open(modalRedeemCode, ngbModalOptions).result.then((result) => {
      if (result == "OK") {
        if (this.promotionalCode == undefined || this.promotionalCode == "") {
          Swal.fire({
            icon: 'error',
            title: 'Debes introducir un código promocional. Por favor, intentalo de nuevo.',
            showConfirmButton: false,
            timer: 4000
          })

          return
        }

        this._promotionManager.getPromotionByName(this.promotionalCode).then(result => {
          if (result) {
            let promotion: PromotionsModel = result as PromotionsModel

            let message = "Vas a canjear: <br/>"
              .concat("Nombre de la promoción: ").concat(promotion.namePromotion).concat("<br/>")
              .concat("Valor de la promoción: ").concat(promotion.valuePromotion.toString()).concat("€<br/>")

            Swal.fire({
              title: '¿Canjear código?',
              html: message,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si',
              cancelButtonText: 'Cancelar'
            })
              .then((result) => {
                if (result.isConfirmed) {
                  this._loadingService.show()

                  let promotionData = {
                    idPromotion: promotion.id,
                    idSession: this.room.sessionId,
                    idUser: this.dataUser.id,
                    idRoom: this.idRoom,
                    valuePromotion: promotion.valuePromotion,
                    promotionOnlyOneUse: promotion.promotionOnlyOneUse
                  }

                  this._redeemPromotionsManager.redeemPromotion(promotionData).then(result => {
                    if (result) {
                      this._userManager.getUserData(this.dataUser.id, this.typeUser).then(result => {
                        if (result) {

                          this.dataUser = result as UserModel

                          this._loadingService.hide()

                          Swal.fire({
                            icon: 'success',
                            title: 'Código promocional validado correctamente. El saldo ha sido añadido a tu cuenta',
                            showConfirmButton: false,
                            timer: 4000
                          })

                          return
                        }
                      })
                    }
                  })
                }

              })
          }
        })
      }
    })
  }

  openModalDonate(modalDonate: any) {
    this._roomManager.getRoom(this.idRoom).then(result => {
      if (result) {
        this.room = result as RoomModel
      }
    })

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    };

    this._modalService.open(modalDonate, ngbModalOptions).result.then((result) => {

      if (result == "OK") {
        if (this.quantityInput == undefined || this.quantityInput <= 0) {
          Swal.fire({
            icon: 'error',
            title: 'Para aportar debes introducir una cantidad válida. Por favor, intentalo de nuevo.',
            showConfirmButton: false,
            timer: 4000
          })

          return
        }
        if (this.quantityInput > this.dataUser.credit) {
          Swal.fire({
            icon: 'error',
            title: 'Credito inferior a la aportación que quieres realizar. Por favor, añade más crédito e intentalo de nuevo.',
            showConfirmButton: false,
            timer: 4000
          })

          return
        }

        Swal.fire({
          title: '¿Realizar aportación?',
          text: "Una vez aportes, está acción no se puede revertir.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        })
          .then((result) => {
            if (result.isConfirmed) {
              this._loadingService.show()

              this._liveRoomManager.getLiveRoomUser(this.currentLiveRoomUserData.id).then(response => {
                if (response) {
                  let liveRoomUser = response as LiveRoomModel

                  liveRoomUser.totalQuantityDonate += this.quantityInput
                  liveRoomUser.quantityDonate = this.quantityInput

                  this.currentLiveRoomUserData.totalQuantityDonate = liveRoomUser.totalQuantityDonate
                  this.currentLiveRoomUserData.quantityDonate = liveRoomUser.quantityDonate

                  this._liveRoomManager.updateDonateLiveRoomUser(this.currentLiveRoomUserData).then(result => {
                    if (result) {
                      this._loadingService.hide()

                      Swal.fire({
                        icon: 'success',
                        title: 'aportación realizada correctamente',
                        showConfirmButton: false,
                        timer: 4000
                      })

                      this.currentLiveRoomUserData = result as LiveRoomModel

                      this.getAllUsersForLiveRoom()
                      this.socketService.emitEvent("doing-update-users")

                      this._roomManager.getRoom(this.idRoom).then(result => {
                        if (result) {
                          this.room = result as RoomModel
                        }
                      })

                      this._userManager.getUserData(this.dataUser.id, this.typeUser).then(result => {
                        if (result) {
                          this.dataUser = result as UserModel
                        }
                      })

                      // TO DO: Añadir un flag en la request para que sea BackEnd quien actualice
                      if (this.quantityInput > this.room.maxDonate) {
                        this.room.maxDonate = this.quantityInput
                        this._roomManager.updateRoom(this.idRoom, this.room).then(result => {
                          if (result) {
                            this.room = result as RoomModel
                          }
                        })
                      }
                    }
                  })

                }

              })
            }
          })
      }
    })
  }

  openModalSendMessageInRoom(modalSendMessageInRoom: any) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    };

    this._modalService.open(modalSendMessageInRoom, ngbModalOptions).result.then((result) => {
      if (result == "OK") {
        if (this.messageTypeSelected == undefined || this.messageTypeSelected == "") {
          Swal.fire({
            icon: 'error',
            title: 'Debes indicar el tipo de mensaje a enviar. Por favor, intentalo de nuevo.',
            showConfirmButton: false,
            timer: 4000
          })

          return
        }

        alert("valor: " + this.roomMessage)
        if (this.roomMessage == undefined || this.roomMessage == "") {
          Swal.fire({
            icon: 'error',
            title: 'Debes introducir un mensaje para enviar a la sala. Por favor, intentalo de nuevo.',
            showConfirmButton: false,
            timer: 4000
          })

          return
        }

        let messageObject = {
          event: "doing-send-room-message",
          messageType: this.messageTypeSelected,
          roomMessage: this.roomMessage,
          nickNameSendMessage: this.currentLiveRoomUserData.nickname
        }

        this.socketService.emitEvent(messageObject)

        Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado correctamente a la sala.',
          showConfirmButton: false,
          timer: 4000
        })

        return

      }
    })
  }

  donateFromThird(userLiveRoomData: { canDonate?: any; id?: any }, value: number) {

    let valueInMoney = (this.room.starValue * value)
    let idUserToDonate = userLiveRoomData.id

    Swal.fire({
      title: 'Estas seguro de que quieres realizar una aportación de: '.concat(valueInMoney.toString()).concat("€?"),
      text: "Está acción, no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, donar cantidad',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this._liveRoomManager.getLiveRoomUser(idUserToDonate).then(result => {
          if (result) {
            let liveRoomUser = result as LiveRoomModel
            let totalQuantityDonate = liveRoomUser.totalQuantityDonate + valueInMoney
            let totalQuantityDonateFromThird = liveRoomUser.totalQuantityDonateFromThird + valueInMoney

            let dataToSend = {
              idRoom: this.idRoom,
              idUserToDonate: idUserToDonate,
              idUserDonate: this.dataUser.id,
              quantityDonate: valueInMoney,
              totalQuantityDonate: totalQuantityDonate,
              totalQuantityDonateFromThird: totalQuantityDonateFromThird,
              valueDonate: value
            }

            this._liveRoomManager.donateFromThird(dataToSend).then(result => {
              if (result) {
                let liveRoomDataUpdated = result as UserModel
                this.dataUser.credit = liveRoomDataUpdated.credit

                Swal.fire(
                  'aportación realizada con éxito',
                  'Tu aportación se ha completado',
                  'success'
                )

                this._userManager.getUserData(this.dataUser.id, this.typeUser).then(result => {
                  if (result) {
                    this.dataUser = result as UserModel
                  }
                })

                this.getAllUsersForLiveRoom()

                this.socketService.emitEvent("doing-update-users")
              }
            })
          }
        })
      }
    })

  }

  passNextUser() {

    if (this.userCurrentConsultingOrValidated != null) {
      this.userCurrentConsultingOrValidated.isInProcessToValidateAudio = false
      this.userCurrentConsultingOrValidated.isUserCurrentConsulting = false

      this.updateLiveRoomUser(false, this.userCurrentConsultingOrValidated, false)
    }

    this.userCurrentConsultingOrValidated = this.liveRoomData[0]

    if (!this.userCurrentConsultingOrValidated.userHaveAudio) {
      this.searchNexUserMatchConditions()
    }
    else if (this.userCurrentConsultingOrValidated.userHaveAudio && ((!this.userCurrentConsultingOrValidated.isUserOnline && this.userCurrentConsultingOrValidated.allowQueryOffline) || (this.userCurrentConsultingOrValidated.isUserOnline))) {
      this.proceedToPassNextUser(this.userCurrentConsultingOrValidated)
    }
    else {
      this.searchNexUserMatchConditions()
    }

  }

  validateUserAudio() {
    Swal.fire({
      title: '¿El audio del usuario se escucha correctamente?',
      text: "Si el audio del usuario se escucha correctamente, pulsa Si para comenzar el tiempo de consulta",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    })
      .then((result) => {
        if (result.isConfirmed) {
          this._loadingService.show()

          let valueToConsolidate = (this.userCurrentConsultingOrValidated.totalQuantityDonate + this.userCurrentConsultingOrValidated.totalQuantityDonateFromThird)

          this.createSessionCreditConsolidated(valueToConsolidate).then(result => {
            if (result) {
              this.userYear = "(".concat(this._utils.getAge(this.userCurrentConsultingOrValidated.birthdayToConsult).toString()).concat(" años)")

              this.showDataUserToConsultBox = true
              this.nameToConsult = this.userCurrentConsultingOrValidated.nameToConsult
              this.birthdayToConsult = this.userCurrentConsultingOrValidated.birthdayToConsult
              this.queryComments = this.userCurrentConsultingOrValidated.queryComments
              this.userCurrentConsultingOrValidated.isInProcessToValidateAudio = false
              this.showValidateAudioButton = false
              this.showFinishSessionButton = true
              this.showCountdown = true
              this.userCurrentConsultingOrValidated.isUserCurrentConsulting = true
              this.userCurrentConsultingOrValidated.isAudioValidated = true
              this.userCurrentConsultingOrValidated.creditConsolidated = valueToConsolidate
              this.userCurrentConsultingOrValidated.totalQuantityDonate = 0
              this.userCurrentConsultingOrValidated.totalQuantityDonateFromThird = 0

              this.updateLiveRoomUser(true, this.userCurrentConsultingOrValidated, false)

              this.startCountdown(this.room.timeToConsult)

              this.addOrQuitUsersWithAudioUpload(false).then(result => {
                if (result) {
                  this._roomManager.updateRoom(this.idRoom, { usersWithAudioUpload: result })
                }
              })

              this.socketService.emitEvent("doing-less-user-to-consult")

              this._loadingService.hide()
            }
          })
        }
      })
  }

  invalidateUserAudio() {
    Swal.fire({
      title: '¿Quieres rechazar el audio del usuario?',
      text: "Si el audio del usuario no se escucha correctamente, pulsa Si para invalidar su audio y poner en revision por su parte",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.userCurrentConsultingOrValidated.isInProcessToValidateAudio = false
          this.showValidateAudioButton = false
          this.showPassNextUserButton = true
          this.userCurrentConsultingOrValidated.isUserCurrentConsulting = false
          this.userCurrentConsultingOrValidated.isAudioValidated = false
          this.userCurrentConsultingOrValidated.userHaveAudio = false

          this.updateLiveRoomUser(true, this.userCurrentConsultingOrValidated, false)

          this.addOrQuitUsersWithAudioUpload(false).then(result => {
            if (result) {
              this._roomManager.updateRoom(this.idRoom, { usersWithAudioUpload: result })
            }
          })

          this.socketService.emitEvent("doing-less-user-to-consult")
        }
      })
  }

  toggleDataUserToConsultChange() {
    if (!this.ownQueryData) {
      this.nameToConsult = ""
      this.birthdayToConsult = null
    }
    else {
      this.nameToConsult = this.dataUser.name
      this.birthdayToConsult = this.dataUser.birthday
    }
  }

  closeSession() {
    if (this.userCurrentConsultingOrValidated.isInProcessToValidateAudio || this.userCurrentConsultingOrValidated.isUserCurrentConsulting) {
      Swal.fire({
        icon: 'error',
        title: 'Debes validar audio o finalizar consulta antes de terminar la sesión.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    Swal.fire({
      title: "¿Cerrar Sesión?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    })
      .then((result) => {
        if (result.isConfirmed) {

          let session = {
            dateEnd: new Date(),
          }

          this.updateSession(session, false)
        }

      })
  }

  goToMyUser() {
    this._guidedTourService.startTour(this.myUserTour)
  }

  startTour() {
    this._guidedTourService.startTour(this.dashboardTour)
  }

  onLoadedMetadata(event: Event) {
    (event.target as HTMLVideoElement).play();
  }

  private searchNexUserMatchConditions() {
    Swal.fire({
      icon: 'warning',
      title: 'El primer usuario no tiene audio. Se busca el próximo usuario para darle paso.',
      timer: 4000
    }).then(() => {
      let isUserWithAudioInSessionFinded = false

      this.liveRoomData.forEach(currentLiveRoomData => {
        if (!isUserWithAudioInSessionFinded) {
          if (currentLiveRoomData.userHaveAudio) {
            if (currentLiveRoomData.allowQueryOffline) {
              this.userCurrentConsultingOrValidated = currentLiveRoomData
              isUserWithAudioInSessionFinded = true
              this.proceedToPassNextUser(this.userCurrentConsultingOrValidated)
            }
            else if (!currentLiveRoomData.allowQueryOffline && currentLiveRoomData.isUserOnline) {
              this.userCurrentConsultingOrValidated = currentLiveRoomData
              isUserWithAudioInSessionFinded = true
              this.proceedToPassNextUser(this.userCurrentConsultingOrValidated)
            }
          }
        }
      })

      if (!isUserWithAudioInSessionFinded) {
        Swal.fire({
          icon: 'error',
          title: 'No hay ningún usuario con audio grabado en la sala, no podemos darle paso a nadie por el momento.',
          showConfirmButton: false,
          timer: 4000
        })

        return
      }
    })
  }

  async startStreamingConsult() {

    try {

      this.localStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      this.localVideo.nativeElement.srcObject = this.localStream

      this.localVideo.nativeElement.muted = true

      this.localStream.getTracks().forEach((track) => {
       
        this.peerConnection.addTrack(track, this.localStream)
      })

      this.peerConnection.addEventListener('icecandidate', (event) => {
        if (event.candidate) {
          this.socketService.emitCustomEvent('icecandidate', event.candidate)
        }
      })
     
      this.videoContainer.nativeElement.style = "display: block"
      this.showStartStreamingConsultButton = false
      this.showStopStreamingConsultButton = true
    }
    catch (err) {
      console.error('Error accessing camera or microphone:', err);
      Swal.fire({
        icon: 'error',
        title: 'La cámara no está disponible en este momento. Asegurate de que no esté siendo usada por otra aplicación',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    this.startCall()

    this.socketService.emitEvent("start-streaming")
  }

  private async startCall() {

    try {
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);
      
      this.socketService.emitCustomEvent('offer', offer);

      setTimeout(async () => {
        if (this.peerConnection.signalingState === 'have-local-offer') {
          console.log('Retrying offer...');
          this.socketService.emitCustomEvent('offer', offer);
        }
      }, 5000);
    } catch (error) {
      console.error('Error starting call:', error);
    }
  }

  stopStreamingConsult() {
    this.videoContainer.nativeElement.style = "display: none"
    this.showStartStreamingConsultButton = true
    this.showStopStreamingConsultButton = false

    let currentStream = this.localVideo.nativeElement.srcObject
    let tracks = currentStream.getTracks()

    tracks.forEach(function (track: any) {
      track.stop()
    })

    this.localVideo.nativeElement.srcObject = null
    clearInterval(this.streamingInterval)

    this.socketService.emitEvent("stop-streaming")
  }

  doMuteOrUnmuteAudioStreaming() {

    if (this.localVideo.nativeElement.muted) {
      this.localVideo.nativeElement.muted = false
      this.isMuted = false
    }
    else {
      this.localVideo.nativeElement.muted = true
      this.isMuted = true
    }
  }

  private loadStreaming() {
  
    this.peerConnection.addEventListener('track', async (event) => {
      const [remoteStream] = event.streams;

      this.remoteVideo.nativeElement.srcObject = remoteStream
      this.inStreaming = true
   
    })

    this.socketService.on('offer', async (offer: any) => {
      try {
        if (this.peerConnection.signalingState !== 'stable') {
          console.warn('PeerConnection is not in stable state. Ignoring offer.');
          return;
        }

        await this.peerConnection.setRemoteDescription(offer)
        const answer = await this.peerConnection.createAnswer()
        await this.peerConnection.setLocalDescription(answer)
        this.socketService.emitCustomEvent('answer', answer)
      } catch (error) {
        console.error('Error handling offer:', error)
      }
    });

    this.socketService.on('answer', async (answer: any) => {
      if (this.peerConnection.signalingState === 'have-local-offer') {
        console.log("Processing answer");
        await this.peerConnection.setRemoteDescription(answer);
      } else {
        console.log("Queueing answer");
        this.answerQueue.push(answer);
      }
    })

    this.socketService.on('icecandidate', async (candidate: any) => {
      try {
        await this.peerConnection.addIceCandidate(candidate)
      } catch (error) {
        console.error('Error adding ICE candidate:', error)
      }
    })

    this.peerConnection.addEventListener('signalingstatechange', async () => {
      if (this.peerConnection.signalingState === 'have-local-offer' && this.answerQueue.length > 0) {
        console.log("Processing queued answer");
        const answer = this.answerQueue.shift()
        await this.peerConnection.setRemoteDescription(answer);
      }
    });
  }

  setupMediaSource(blob: Blob) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.remoteVideo.nativeElement.src = fileReader.result as string;
    };
    fileReader.readAsDataURL(blob);
  }

  private initData() {

    this._roomManager.getRoom(this.idRoom).then(result => {
      if (result) {
        this.room = result as RoomModel
        this._seerManager.getSeerById(this.room.seerId).then(result => {
          if (result) {
            this.seerData = result as SeerModel

            this.seerData.photoURL = this.storageAccountImagesPath.concat(this.seerData.photoPath)
          }
        })
      }

      this.initRoom()
      
    })
  }

  private createSessionCreditConsolidated(valueConsolidated: number) {
    return new Promise((resolve) => {

      this._userManager.getUserData(this.userCurrentConsultingOrValidated.idUser, this.typeUser).then(result => {
        if (result) {
          let userData = result as UserModel

          let sessionData = {
            idUser: this.dataUser.id,
            idSession: this.room.sessionId,
            idRoom: this.room.id,
            nameUser: userData.name,
            nameSession: this.room.nameRoom,
            valueConsolidated: valueConsolidated,
            videoSecondsStartQuery: this.cleanTime(),
          }

          this._sessionCreditConsolidatedManager.createSessionCreditConsolidated(sessionData)

          resolve(true)
        }
      })
    })
  }

  private updateSession(session: { dateEnd: Date }, stateRoom: boolean) {
    this._sessionsManager.updateSession(this.room.sessionId, session).then(result => {
      if (result) {
        let roomDataToEdit: RoomModel = new RoomModel()
        roomDataToEdit.state = stateRoom

        this._roomManager.updateRoom(this.idRoom, roomDataToEdit).then(result => {
          if (result) {
            this.room = result as RoomModel
          }
        })

        this._router.navigate(['sessions'])
      }
    })
  }

  private getUserDonation(currentLiveRoomData: LiveRoomModel) {
    this._donationManager.getUserDonation(this.dataUser.id, currentLiveRoomData.id).then(result => {
      if (result) {
        let donationData = result as DonationModel
        if (donationData.valueDonate > 0) {
          currentLiveRoomData.canDonate = false
          this.setUserStars(currentLiveRoomData.stars, donationData.valueDonate)
        }
        else {
          currentLiveRoomData.canDonate = true
        }
      }
    })
  }

  private initRoom() {
    if (this.typeUser != "2") {

      this._liveRoomManager.saveUserDataToLiveRoom(this.idRoom, this.dataUser.id).then(result => {
       
        if (result) {
          this.currentLiveRoomUserData = result as LiveRoomModel
          this.initializeSocket()
          this.getAllUsersForLiveRoom()
          this.loadStreaming()

          if (this.currentLiveRoomUserData.isNewUserInRoom) {
            this._guidedTourService.startTour(this.dashboardTour)
          }
        }
      })
    }
    else {
      this.initializeSocket()
      this.getAllUsersForLiveRoom()
      this.loadStreaming()
    }
  }

  private reproduceBlob(buttonsContainer: { querySelectorAll: (arg0: string) => HTMLAudioElement[] }, currentLiveRoomUser: LiveRoomModel, permissions: string) {

    let paramsToGetSasToken = {
      container: environment.BlobContainerAudio,
      blobName: currentLiveRoomUser.userAudioPath,
      permissions: permissions
    }

    this._azureManager.getSasToken(paramsToGetSasToken).then((sasTokenUri: any) => {
      if (sasTokenUri) {
        currentLiveRoomUser.userAudioPathSAS = sasTokenUri

        let voiceRecordedElement = <HTMLAudioElement>buttonsContainer.querySelectorAll("#voice-recorded")[0]

        voiceRecordedElement.play()
        currentLiveRoomUser.isPlayingAudio = true
        this.isCurrentPlayingAudio = true

        // TO DO: Corregir funcionamiento, sale en bucle el popUp para validar audio
        /*if (this.typeUser == "2" && (currentLiveRoomUser.audioDuration != 0 && currentLiveRoomUser.audioDuration != null && currentLiveRoomUser.audioDuration != undefined)) {
          setTimeout(() => {
            Swal.fire({
              title: 'Recuerda validar el audio del usuario antes de empezar la consulta',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Validar',
              cancelButtonText: 'Aún no',
              allowOutsideClick: false
            })
              .then((result) => {
                if (result.isConfirmed) {
                  this.validateUserAudio()
                }
                else{
   
                }
              })
   
          }, (currentLiveRoomUser.audioDuration + 10) * 1000)
        }*/
      }
    })
  }

  private initializeSocket() {
   
    this.socketService = new SocketProviderConnect(this._cookieService)
    this.socketService.outEven.subscribe((res: any) => {

      switch (res) {
        case "user-connected":
          this.updateUserState(true)
          break
        case "user-disconnected":
          this.updateUserState(false)
          break
        case "doing-update-users":
          this.getAllUsersForLiveRoom()
          break
        case "doing-new-user-to-consult":
          this.usersPendingToConsult++
          this.timeEstimatedFinalizeSession = (this.room.timeToConsult / 60) * this.usersPendingToConsult
          break
        case "doing-less-user-to-consult":
          this.usersPendingToConsult--
          break
        case "start-streaming":
          this.inStreaming = true
          this.videoContainer.nativeElement.style = "display: block"
          this.remoteVideo.nativeElement.style = "display: block"
          break
        case "stop-streaming":
          this.inStreaming = false
          this.videoContainer.nativeElement.style = "display: none"
          this.remoteVideo.nativeElement.style = "display: none"
          break
      }

      if ((res.event != null && res.event != undefined) && res.event.includes("doing-send-room-message")) {
        console.log("res: ", res)
        if (res.messageType == "user") {
          Swal.fire('Nuevo mensaje en sala recibido')
          let nickNameSendMessage = JSON.stringify(res.nickNameSendMessage)
          let message = JSON.stringify(res.roomMessage)
          let roomMessage = "[".concat(JSON.parse(nickNameSendMessage)).concat("]: ").concat(JSON.parse(message))
          this.userRoomMessage = roomMessage
        }
        else if (res.messageType == "seer") {
          Swal.fire('Nuevo mensaje en sala recibido')
          let nickNameSendMessage = JSON.stringify(res.nickNameSendMessage)
          let message = JSON.stringify(res.roomMessage)
          let roomMessage = "[".concat(JSON.parse(nickNameSendMessage)).concat("]: ").concat(JSON.parse(message))
          this.seerRoomMessage = roomMessage
        }
      }

      console.log("Desde Socket:", res)
    })
  }

  private updateUserState(state: boolean) {
    this.currentLiveRoomUserData.isUserOnline = state
    this.updateLiveRoomUser(true)
  }

  private initializeStarsArray() {
    return [
      {
        id: 1,
        icon: 'star',
        class: 'star-gray star-hover star'
      },
      {
        id: 2,
        icon: 'star',
        class: 'star-gray star-hover star'
      },
      {
        id: 3,
        icon: 'star',
        class: 'star-gray star-hover star'
      },
      {
        id: 4,
        icon: 'star',
        class: 'star-gray star-hover star'
      },
      {
        id: 5,
        icon: 'star',
        class: 'star-gray star-hover star'
      }

    ];
  }

  private setUserStars(userStars: any[], value: number) {
    userStars.filter((star) => {
      if (star.id <= value) {
        star.class = 'star-gold star';

      } else {
        star.class = 'star-gray star';
      }

      return star;
    });
  }


  private proceedToPassNextUser(userCurrentConsultingOrValidated: LiveRoomModel) {
    if (!userCurrentConsultingOrValidated.userHaveAudio) {
      Swal.fire({
        icon: 'error',
        title: 'No podemos dar paso al siguiente usuario puesto que este, no tiene audio grabado.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    this._userManager.getUserData(userCurrentConsultingOrValidated.idUser, this.typeUser).then(result => {
      if (result) {
        this.dataUser = result as UserModel

        let message = "Sus datos son: <br/>"
          .concat("Nombre: ").concat(this.dataUser.name).concat("<br/>")
          .concat("Email: ").concat(this.dataUser.email).concat("<br/>")
          .concat("<br/>Recuerda comprobar que el audio del usuario es legible y pulsar el botón Validar Audio")

        Swal.fire({
          title: '¿Pasar al siguiente usuario?',
          html: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: false
        })
          .then((result) => {
            if (result.isConfirmed) {

              userCurrentConsultingOrValidated.isUserCurrentConsulting = false
              userCurrentConsultingOrValidated.isInProcessToValidateAudio = true
              this.showPassNextUserButton = false
              this.showValidateAudioButton = true

              this.currentLiveRoomUserData = userCurrentConsultingOrValidated

              this.updateLiveRoomUser(true)
            }

          })
      }
    })

  }

  private getModalDonateDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }

  private startCountdown(seconds: number) {
    let counter = seconds;

    this.intervalQueryRemaining = setInterval(() => {
      this.timeRemaining = this.transformSeconds(counter)

      counter--

      if (counter < 0) {
        clearInterval(this.intervalQueryRemaining)
        console.log('Time to consult expired!')
      }
    }, 1000);
  }

  private stopCountdown() {
    clearInterval(this.intervalQueryRemaining)
    this.timeRemaining = undefined
  }

  private transformSeconds(value: number): string {
    const minutes: number = Math.floor(value / 60)
    return minutes.toString().padStart(2, '0') + ':' +
      (value - minutes * 60).toString().padStart(2, '0')
  }

  private addOrQuitUsersWithAudioUpload(isAdd: boolean) {
    return new Promise((resolve) => {
      this._roomManager.getRoom(this.room.id).then(result => {

        if (result) {
          let currentRoom = result as RoomModel

          if (isAdd) {
            resolve(currentRoom.usersWithAudioUpload + 1)
          }
          else {
            resolve(currentRoom.usersWithAudioUpload - 1)
          }

        }
      })
    })
  }

  endQuery() {
    Swal.fire({
      title: '¿Finalizar consulta?',
      text: "Si la consulta con el usuario ha finalizado, pulsa Si",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.showDataUserToConsultBox = false
          this.showValidateAudioButton = false
          this.showFinishSessionButton = false
          this.showCountdown = false
          this.showPassNextUserButton = true
          this.userCurrentConsultingOrValidated.isInProcessToValidateAudio = false
          this.userCurrentConsultingOrValidated.isUserCurrentConsulting = false
          this.userCurrentConsultingOrValidated.isAudioValidated = true
          this.userCurrentConsultingOrValidated.userHaveAudio = false
          this.userCurrentConsultingOrValidated.userAudioPath = ""
          this.userCurrentConsultingOrValidated.ownQueryData = true
          this.userCurrentConsultingOrValidated.allowQueryOffline = true

          this.stopCountdown()

          this.addOrQuitUsersWithAudioUpload(false).then(result => {
            if (result) {
              this._roomManager.updateRoom(this.idRoom, { usersWithAudioUpload: result })
            }
          })

          this.updateLiveRoomUser(true, this.userCurrentConsultingOrValidated, false)
        }
      })
  }

  private cleanTime() {
    return Math.round(new Date().getTime())
  }

}

import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable()

export class Utils {

	constructor() { }

	public IsJsonString(str: string) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}

		return true;
	}

	public getAge(date: string | number | Date) {
		var today = new Date()
		var birthDate = new Date(date)
		var age = today.getFullYear() - birthDate.getFullYear()
		var m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}
		return age
	}

	public getAzureStorageAccountPathForImages() {
		return ''//environment.AzureStorageAccountPath.concat(environment.BlobContainerImage).concat("/")
	}

	public getIfBrowserPermissionsAreGranted(permissionsParams: MediaStreamConstraints | undefined) {
		return new Promise((resolve) => {
			navigator.mediaDevices.getUserMedia(permissionsParams)
				.then(function (stream) {
					if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
						//code for when none of the devices are available

						resolve(false)

					} else {
						// code for when both devices are available
						resolve(true)
					}
				})
				.catch(function (error) {
					// code for when there is an error
					resolve(false)
				});
		})
	}

}

export class SeerModel {  
    public id!: string
    public name!: string 
    public firstName!: string  
    public lastName!: string 
    public email!: string 
    public password!: string
    public photoPath!: string
    public photoURL!: string
    public provider!: string
    public rol!: string
    public introduction!: string
    public allowPrivateSession!: boolean
}
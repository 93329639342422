import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class UsersBackendService {
  public httpOptions!: HttpHeaders
  private _usersEndPoint: string

  constructor(
    public _httpClient: HttpClient
  ) {
    this._usersEndPoint = environment.APIEndPointBackend + environment.Users
  }

  /* REGISTER SOCIAL USER */
  public registerSocialUser(socialUserData: any): Observable<any> {

    return this._httpClient.post(this._usersEndPoint + environment.RegisterSocialUser, socialUserData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when register social user: ", error.error)
          return error
        })
      )
  }

  /* GET SOCIAL USER */
  public getSocialUser(userId: any): Observable<any> {
    return this._httpClient.post(this._usersEndPoint + environment.GetSocialUser, userId)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("User not registered: ", error.error)
          return error
        })
      )
  }

  /* LOGIN NATIVE USER */
  public loginNativeUser(userData: any): Observable<any> {
    return this._httpClient.post(this._usersEndPoint + environment.Login, userData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("User not registered: ", error.error)
          return error
        })
      )
  }

   /* REGISTER NATIVE USER */
   public registerNativeUser(userData: any): Observable<any> {
    return this._httpClient.post(this._usersEndPoint + environment.RegisterUser, userData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("User not registered: ", error)
          return error
        })
      )
  }

  /* UPDATE TO USER */
  public updateUser(id: string, userData: any): Observable<any> {
    return this._httpClient.put(this._usersEndPoint + id, userData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("User not updated: ", error.error)
          return error
        })
      )
  }

  /* UPDATE USER CREDIT */
  public updateUserCredit(id: string, dataToUpdateUserCredit: any): Observable<any> {
    return this._httpClient.put(this._usersEndPoint + environment.UpdateUserCredit + id, dataToUpdateUserCredit)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("User not updated: ", error.error)
          return error
        })
      )
  }

  /* UPDATE NICKNAME USER */
  public updateNicknameUser(id: string, userData: any): Observable<any> {
    return this._httpClient.put(this._usersEndPoint + environment.UpdateNickname +  id, userData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("User Nickname not updated: ", error.error)
          return error
        })
      )
  }

  /* GET USER DATA */
  public getUserData(userId: string): Observable<any> {
    return this._httpClient.get(this._usersEndPoint + environment.GetUserData + userId)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Cannot get credit userId: ", error)
          return error
        })
      )
  }


}
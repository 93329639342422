import { Injectable } from '@angular/core'
import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { throwError, Observable, BehaviorSubject } from "rxjs"
import { catchError, filter, take, switchMap } from "rxjs/operators"
import { OAuth2Service } from '../../services/oauth2.service'
import Swal from 'sweetalert2'
import { OAuth2BackendService } from '../oauth2-backend.service'

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor(
        private _oauth2Service: OAuth2Service,
        private _oauth2BackendService: OAuth2BackendService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401 || error.status == 403) {
                    return this.handle401Error(req, next);
                }
                else if(error.status == 404){
                    Swal.fire({
                        icon: 'error',
                        title: "Ocurrió un error procesando la petición. Seguramente el recurso no esté disponible o no exista. De persistir, por favor, ponte en contacto con: contacto@totonline.es",
                        showConfirmButton: false,
                        timer: 4000
                    })
                }
                else if (error.status == 405) {
                    Swal.fire({
                        icon: 'error',
                        title: "Ocurrió un error enviando el email. Por favor, inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es",
                        showConfirmButton: false,
                        timer: 4000
                    })

                    return throwError(() => "serverError");
                }
                else if (error.status == 400) {
                    Swal.fire({
                        icon: 'error',
                        title: "Ocurrió un error en el envío de la petición. Por favor, inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es",
                        showConfirmButton: false,
                        timer: 4000
                    })

                    return throwError(() => "serverError");
                }
                else if (error.status == 500) {
                    Swal.fire({
                        icon: 'error',
                        title: "Ocurrió un error inesperado en el servidor. Por favor, inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es",
                        showConfirmButton: false,
                        timer: 4000
                    })

                    return throwError(() => "serverError");
                }
                return throwError(() => "unknown error in request");
                
            })
        ) as Observable<HttpEvent<any>>
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);

            return this._oauth2BackendService.getAccessToken().pipe(
                switchMap((response: any) => {
                    this.isRefreshingToken = false;

                    this._oauth2Service.saveToken(response.access_token);
                    this.tokenSubject.next(response.access_token);

                    return next.handle(this.addToken(request, response.access_token));
                }),
                catchError((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: "Ocurrió un error inesperado. Por favor, inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es",
                        showConfirmButton: false,
                        timer: 4000
                    })

                    this.isRefreshingToken = false;
                    return throwError(() => err);
                })
            ) 
        }

        return this.tokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addToken(request, token)))
        );
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
    }
}


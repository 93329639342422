import { Injectable, EventEmitter, Output } from '@angular/core'

import { Socket } from 'ngx-socket-io'
import { environment } from 'src/environments/environment'
import { CookieService } from 'ngx-cookie-service'

@Injectable()

export class SocketProviderConnect extends Socket {

    @Output() outEven: EventEmitter<any> = new EventEmitter()

    constructor(
        private _cookieService: CookieService
    ) {
        super({
            url: environment.ServerSocket,
            options: {
                query: {
                    nameRoom: _cookieService.get('Room'),
                    idUser: _cookieService.get('UserLiveRoom'),
                    typeUser: JSON.parse(_cookieService.get('Type'))
                }
            }

        })

        this.listen()

    }

    listen = () => {
        this.ioSocket.on('event', (res: any) => this.outEven.emit(res))
    }

    emitEvent = (payload: any) => {
        console.log(payload)
        this.ioSocket.emit('event', payload);
    }

    emitCustomEvent = (customEvent: any, payload: any) => {
        this.ioSocket.emit(customEvent, payload);
    }
}

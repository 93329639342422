import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class LiveRoomBackendService {
	public httpOptions!: HttpHeaders
	private _liveRoomEndPoint: string

	constructor(
		public _httpClient: HttpClient
	) {
		this._liveRoomEndPoint = environment.APIEndPointBackend + environment.LiveRoom
	}

	/* GET LIVE ROOM USER */
	public getLiveRoomUser(idUser: string): Observable<any> {
		return this._httpClient.get(this._liveRoomEndPoint + environment.GetLiveRoomUser + idUser)
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when get live room user: ", error)
					return error
				})
			)
	}

	/* GET LIVE ROOM USER BY ID ROOM */
	public getLiveRoomUserByIdRoom(idUser: string, idRoom: any): Observable<any> {
		return this._httpClient.get(this._liveRoomEndPoint + environment.GetLiveRoomUserByIdRoom + idUser.concat("/").concat(idRoom))
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when get live room user by Room Id: ", error)
					return error
				})
			)
	}

	// ADD USER TO LIVE ROOM
	public addUserToLiveRoom(userData: any): Observable<any> {

		return this._httpClient.post(this._liveRoomEndPoint, userData)
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when save live room data: ", error)
					return error
				})
			)
	}

	// GET ALL USERS FOR LIVE ROOM
	public getAllUsersForLiveRoom(idRoom: string): Observable<any> {
		return this._httpClient.get(this._liveRoomEndPoint + idRoom)
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when get all Users for Live Room: ", error)
					return error
				})
			)
	}

	/* UPDATE DONATE LIVE ROOM USER */
	public updateDonateLiveRoomUser(id: string, userData: any): Observable<any> {
		return this._httpClient.put(this._liveRoomEndPoint + environment.UpdateDonateLiveRoomUser + id, userData)
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when update donate: ", error)
					return error
				})
			)
	}

	/* UPDATE LIVE ROOM USER */
	public updateLiveRoomUser(id: string, userData: any): Observable<any> {
		return this._httpClient.put(this._liveRoomEndPoint + id, userData)
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when update live room user: ", error)
					return error
				})
			)
	}

	/* DONATE FROM THIRD */
	public donateFromThird(data: any): Observable<any> {
		console.log("params", data)
		return this._httpClient.post(this._liveRoomEndPoint + environment.DonateFromThird, data)
			.pipe(
				map((data: any) => {
					return data;
				}),
				catchError(error => {
					console.log("Error when donate from third: ", error)
					return error
				})
			)
	}
}
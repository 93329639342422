import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { SessionsModel } from '../models/sessions/sessions.model'
import { SessionsBackendService } from '../services/sessions-backend.service'

@Injectable()

export class SessionsManager {

    private sessionsAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _sessionsBackendService: SessionsBackendService
    ) { }

    ngOnDestroy() {
        if (this.sessionsAPI != null) {
            this.sessionsAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getSessions() {
        return new Promise((resolve) => {
            this.sessionsAPI = this._sessionsBackendService.getSessions()
			.pipe(takeUntil(this.destroy$))
			.subscribe((response: any) => {
				if (response.code == 200) {
					console.log("Sessions retrieved Successfully")

				    resolve(response.sessions_data)
                }

			},
				error => {
					console.log("Response not success: ", <any>error)
                    resolve(false)
				});
        })
    }

    updateSession(sessionId: string, sessionToUpdate: { dateEnd: Date }) {
        return new Promise((resolve) => {
            this.sessionsAPI = this._sessionsBackendService.updateSession(sessionId, sessionToUpdate)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Session was Updated Successfully")
                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    getSessionById(idSession: string) {
        return new Promise((resolve) => {
            this.sessionsAPI = this._sessionsBackendService.getSessionById(idSession)
			.pipe(takeUntil(this.destroy$))
			.subscribe((response: any) => {
				if (response.code == 200) {
					console.log("Session retrieved Successfully")
					let sessionData: SessionsModel = response.sessions_data

                    resolve(sessionData)
				}

			},
				error => {
					console.log("Response not success: ", <any>error)
                    resolve(false)
				});
        })
    }

    createSession(session: any) {
        return new Promise((resolve) => {
            this.sessionsAPI = this._sessionsBackendService.createSession(session)
			.pipe(takeUntil(this.destroy$))
			.subscribe((response: any) => {
				if (response.code == 200) {
					console.log("New Session is Created Successfully")
					let sessionData: SessionsModel = response.sessions_data

				    resolve(sessionData)
                }

			},
				error => {
					console.log("Response not success: ", <any>error)
                    resolve(false)
				});
        })
    }
}
import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { PromotionsBackendService } from '../services/promotions-backend.service'

@Injectable()

export class PromotionManager {

    private promotionsAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _promotionsBackendService: PromotionsBackendService
    ) { }

    ngOnDestroy() {
        if (this.promotionsAPI != null) {
            this.promotionsAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    createPromotion(promotionModel: any) {
        return new Promise((resolve) => {
            this.promotionsAPI = this._promotionsBackendService.createPromotion(promotionModel)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("New Promotion is Created Successfully")

                        Swal.fire({
                            icon: 'success',
                            title: "Nueva promoción creada correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)
                    }
                    else if(response.code == 201){
                        Swal.fire({
                            icon: 'error',
                            title: "El nombre de la promoción, ya está en uso",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(false)
                    }

                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    getPromotionByName(promotionalCode: string) {
        return new Promise((resolve) => {
            this.promotionsAPI = this._promotionsBackendService.getPromotionByName(promotionalCode)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Promotion retrieved successful")
                        resolve(response.promotions_data)

                    }
                    else if (response.code == 201) {
                        Swal.fire({
                            icon: 'error',
                            title: 'El código introducido, no es válido.',
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(false)

                        return
                    }

                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    getPromotions() {
        return new Promise((resolve) => {
            this.promotionsAPI = this._promotionsBackendService.getPromotions()
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Promotions retrieved Successfully")

                        resolve(response.promotions_data)
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrió un error recuperando las promociones.',
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(false)
                    }

                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    deletePromotionById(idPromotion: any) {
        return new Promise((resolve) => {
            this.promotionsAPI = this._promotionsBackendService.deletePromotionById(idPromotion)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Promoción eliminada correctamente',
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updatePromotion(idPromotion: string, promotionData: any) {
        return new Promise((resolve) => {
            this.promotionsAPI = this._promotionsBackendService.updatePromotion(idPromotion, promotionData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        let promotion = response.promotions_data
                        console.log("Promotion is Updated Successfully")

                        Swal.fire({
                            icon: 'success',
                            title: "Promoción actualizada correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(promotion)    
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }
}
export class RoomModel {  
    public id!: string
    public sessionId!: string
    public seerId!: string
    public seerName!: string
    public seerEmail!: string 
    public seerPhotoPath!: string
    public seerPhotoURL!: string
    public idYouTubeChannel!: string
    public urlStreamingNinja!: string
    public urlBrowserNinja!: string
    public minimumAportation!: number
    public timeToConsult!: number
    public starValue!: number
    public nameRoom!: string 
    public dateRoom!: string
    public timeRoom!: string  
    public maxDonate!: number
    public state!: boolean
    public minimumNumberMessagesToStart!: number
    public usersWithAudioUpload!: number
    public showDate!: boolean
}
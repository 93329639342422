import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { OnlineSessionComponent } from './components/pages/online-session/online-session.component'

import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { AuthInterceptor } from './services/http-interceptors/auth.interceptor'
import { ErrorInterceptor } from './services/http-interceptors/error.interceptor'

// FA Icons
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

// Custom Views
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgSwitcheryModule } from 'angular-switchery-ios'

// GUIDED TOUR
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour'

// Material elements
import { MatIconModule } from '@angular/material/icon'
import { MatRadioModule } from '@angular/material/radio' 

/* Utils */
import { Utils } from './utilities/utils'

//Services
import { BlobModule } from 'angular-azure-blob-service'
import { UserManager } from './managers/user.manager'
import { UsersBackendService } from './services/users-backend.service'   
import { RoomManager } from './managers/room.manager'
import { RoomsBackendService } from './services/rooms-backend.service'
import { LiveRoomManager } from './managers/live-room.manager'
import { LiveRoomBackendService } from './services/live-room-backend.service'
import { SeerManager } from './managers/seer.manager'
import { SeersBackendService } from './services/seers-backend.service'
import { DonationManager } from './managers/donation.manager'
import { DonationBackendService } from './services/donation-backend.service'
import { PromotionManager } from './managers/promotion.manager'
import { PromotionsBackendService } from './services/promotions-backend.service'
import { RedeemedPromotionsManager } from './managers/redeemed-promotions.manager'
import { RedeemedPromotionsBackendService } from './services/redeemed-promotions.backend.service'
import { SessionsManager } from './managers/sessions.manager'
import { SessionsBackendService } from './services/sessions-backend.service'
import { SessionCreditConsolidatedManager } from './managers/session-credit-consolidated.manager'
import { SessionCreditConsolidatedBackendService } from './services/session-credit-consolidated-backend.service'
import { AzureManager } from './managers/azure.manager'
import { AzureBackendService } from './services/azure-backend.service'
import { OAuth2Service } from './services/oauth2.service'
import { OAuth2BackendService } from './services/oauth2-backend.service'

@NgModule({
  declarations: [
    AppComponent,
    OnlineSessionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GuidedTourModule,
    NgbModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgSwitcheryModule,
    MatRadioModule,
    BlobModule.forRoot(),
    FontAwesomeModule
  ],
  providers: [
    GuidedTourService,
    Utils,
    UserManager,
    UsersBackendService,
    RoomManager,
    RoomsBackendService,
    LiveRoomManager,
    LiveRoomBackendService,
    SeerManager,
    SeersBackendService,
    DonationManager,
    DonationBackendService,
    PromotionManager,
    PromotionsBackendService,
    RedeemedPromotionsManager,
    RedeemedPromotionsBackendService,
    SessionsManager,
    SessionsBackendService,
    SessionCreditConsolidatedManager,
    SessionCreditConsolidatedBackendService,
    AzureManager,
    AzureBackendService,
    OAuth2Service,
    OAuth2BackendService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { RedeemedPromotionsModel } from '../models/promotions/redeemed-promotions.model'
import { RedeemedPromotionsBackendService } from '../services/redeemed-promotions.backend.service'

@Injectable()

export class RedeemedPromotionsManager {

    private redeemedPromotionsAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _redeemedPromotionsBackendService: RedeemedPromotionsBackendService
    ) { }

    ngOnDestroy() {
        if (this.redeemedPromotionsAPI != null) {
            this.redeemedPromotionsAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    redeemPromotion(promotionData: { idPromotion: string; idSession: string; idUser: string; idRoom: any; valuePromotion: number; promotionOnlyOneUse: boolean }) {
        return new Promise((resolve) => {
            this.redeemedPromotionsAPI = this._redeemedPromotionsBackendService.redeemPromotion(
                promotionData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                  if (response.code == 200) {
                    console.log("Redeemed promotion save successful")
                    resolve(true)
                    
                  }
                  else if (response.code == 201) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Ya has utilizado este código promocional.',
                      showConfirmButton: false,
                      timer: 4000
                    })

                    return

                    resolve(false)
                  }

                },
                  error => {
                    console.log("Response not success: ", <any>error)
                    resolve(false)
                  });
        })
    }

    getRedeemPromotionsBySessionId(idSession: string) {
      return new Promise((resolve) => {
        this.redeemedPromotionsAPI = this._redeemedPromotionsBackendService.getRedeemPromotionsBySessionId(idSession)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          if (response.code == 200) {
            console.log("Redeemed in session retrieved Successfully")
            let redeemedPromotionsData: RedeemedPromotionsModel[] = response.redeemed_promotions_data

            resolve (redeemedPromotionsData)
          }

        },
          error => {
            console.log("Response not success: ", <any>error)
            resolve(false)
          });
      })
  }
}
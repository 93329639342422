import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment';

@Injectable()

export class OAuth2BackendService {
    public httpOptions!: HttpHeaders
    private _oauth2EndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._oauth2EndPoint = environment.APIEndPointBackend + environment.OAuth2
    }

    /* GET AcessToken */
    public getAccessToken(): Observable<any> {

        return this._httpClient.get(this._oauth2EndPoint + environment.ClientId)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get access token: ", error)
                    return error
                })
            )
    }

}
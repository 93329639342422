import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators";
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class SessionsBackendService {
    public httpOptions!: HttpHeaders
    private _sessionsEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._sessionsEndPoint = environment.APIEndPointBackend + environment.Sessions
    }

    /* CREATE SESSION */
    public createSession(session: any): Observable<any> {

        return this._httpClient.post(this._sessionsEndPoint, session)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when create session: ", error)
                    return error
                })
            )
    }

    /* GET SESSIONS */
    public getSessions(): Observable<any> {

        return this._httpClient.get(this._sessionsEndPoint)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get sessions: ", error)
                    return error
                })
            )
    }

    /* DELETE SESSION BY ID */
    public deleteSessionById(idSession: any): Observable<any> {

        return this._httpClient.request("delete", this._sessionsEndPoint, { body: { id: idSession } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when delete session: ", error.error)
                    return error
                })
            )
    }

     /* GET SESSION BY ID */
     public getSessionById(idSession: string): Observable<any> {

        return this._httpClient.get(this._sessionsEndPoint + environment.GetSessionById + idSession)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get session by Id: ", error)
                    return error
                })
            )
    }


    // UPDATE SESSION
    public updateSession(idSession: string, sessionData: any): Observable<any> {

        return this._httpClient.put(this._sessionsEndPoint + idSession, sessionData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when updating session: ", error)
                    return error
                })
            )
    }
}
import { Injectable, OnInit } from '@angular/core'

@Injectable()

export class OAuth2Service implements OnInit {
    

    ngOnInit() {
    }

    getToken(){
        return localStorage.getItem("token")
    }

    saveToken(token: string){
        return localStorage.setItem("token", token)
    }
}

import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DonationBackendService } from '../services/donation-backend.service'

@Injectable()

export class DonationManager {

    private donationAPI!: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _donationBackendService: DonationBackendService
    ) { }

    ngOnDestroy() {
        if (this.donationAPI != null) {
            this.donationAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getUserDonation(idUser: string, idConsult: string) {
        return new Promise((resolve) => {
            this.donationAPI = this._donationBackendService.getDonationByIdUserDonate(idUser, idConsult)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Get donation data retrieved success")

                        resolve(response.donation_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }
}